import styled from '@emotion/styled'
import React from 'react'

const HeaderConatiner = styled.div`
  background-color: rgb(var(--dark-blue-grey));
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 71px 0;
  align-items: center;
  @media (max-width: 800px) {
    padding: 48px 12px;
  }
`

const TitleHeader = styled.h1`
  font-size: 51px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.98;
  letter-spacing: -0.7px;
  text-align: center;
  color: rgb(var(--very-light-pink));
  margin: 12px 0;
  @media (max-width: 800px) {
    font-size: 38px;
  }
`

const TitleSubHeading = styled.h2`
  opacity: 0.8;
  font-family: Objectivity;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.14px;
  text-align: center;
  color: #ffffff;
  margin: 6px 0;
  @media (max-width: 800px) {
    /* display: none */
    font-size: 14px;
  }
`

const StaticHeader = ({ title, sub, children }) => {
  return (
    <HeaderConatiner>
      <TitleHeader> {title} </TitleHeader>
      {sub && <TitleSubHeading> {sub} </TitleSubHeading>}
      {children}
    </HeaderConatiner>
  )
}

export default StaticHeader
