import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { useContext } from 'react'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import StaticHeader from '../../components/StaticHeader'
import ZenDesk from '../../components/ZenDesk'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'

function groupBy(list, keyGetter) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

const Body = styled.div`
  color: #212121;
  font-family: Objectivity;
  font-size: 16px;
  line-height: 23px;
  margin: 16px 13px;
  width: calc(100% - 26px);
`
const StaticContainer = styled.section`
  margin-top: 130px;
  @media (max-width: 1025px) {
    margin-top: 0px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
  background: white;
`
const TextContaner = styled.div`
  width: calc(100% - 26px);
  max-width: 800px;
`

const StaticPage = ({ data, pageContext }) => {
  // const product = data.shopifyProduct
  const { currency, language } = useContext(StoreContext)

  const staticData = data.allCockpitStaticPages.edges
  const grouped = groupBy(staticData, (el) => el.node.lang)
  const localisedStaticData = grouped.get(
    language.replace('au', 'default').replace('root', 'default')
  )
  const flattenedData = localisedStaticData.map((val) => ({
    title: val.node.title.value,
    body: val.node.body.value,
  }))

  const body = flattenedData[0].body
  return (
    <Layout location="static">
      <StaticContainer>
        <SEO title={flattenedData[0].title} />
        <StaticHeader title={flattenedData[0].title}> </StaticHeader>
        <TextContaner>
          <Body dangerouslySetInnerHTML={{ __html: body }} />
          {pageContext.handle === 'get-in-touch' && <ZenDesk domain="july" />}
        </TextContaner>
      </StaticContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($handle: String!) {
    allCockpitStaticPages(filter: { handle: { value: { eq: $handle } } }) {
      edges {
        node {
          handle {
            value
          }
          title {
            value
          }
          body {
            value
          }
          body_us {
            value
          }
          body_sg {
            value
          }
          body_zh {
            value
          }
          body_sg_zh {
            value
          }
          lang
        }
      }
    }
  }
`

export default StaticPage
