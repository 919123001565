import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const StyledLink = styled.span`
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
  padding: 12px 16px;
  min-height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const P = styled.p`
  font-size: 16px;
  word-wrap: break-word;
  text-align: left;
  color: #475f4c;
  font-weight: 500;
  line-height: 17px;
`

const Content = styled.div`
  position: relative;
  background-color: #fff;
`

const CollapsableContet = styled.div`
  background-color: #fff;
  padding: 10px 16px 10px 16px;
  transition: max-height 0.4s, padding 0.4s;
  max-height: 1500px;
  overflow: hidden;
  ${(props) => collapsed(props)};
`

const collapsed = (props) => {
  if (props.collapsed === true) {
    return `
        max-height: 0px;
        padding: 0px 16px 0px 16px;
      `
  }
}

const Section = ({ title, body, saved, saveSection, defaultOpen }) => {
  let [open] = useState(!defaultOpen)
  // const SaveArticle = () => {
  //   saveSection()
  // }
  return (
    <div>
      <Content title>
        <StyledLink content onClick={(e) => e.preventDefault()}>
          <P large>{title}</P>
          {/* <i className={`fas fa-angle-${open ? "down" : "up"}`} /> */}
        </StyledLink>
      </Content>
      <CollapsableContet collapsed={open}>
        {typeof body === 'string' || body instanceof String ? (
          <div dangerouslySetInnerHTML={{ __html: open ? body : body }} />
        ) : (
          <div> {body} </div>
        )}
      </CollapsableContet>
    </div>
  )
}

Section.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  saved: PropTypes.bool,
  saveSection: PropTypes.any,
}
Section.defaultProps = {
  title: '',
  body: '',
}
export default Section
