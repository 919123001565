import React, { useContext } from 'react'

import StoreContext from '../../context/StoreContext'
import FeedbackForm from './FeedbackForm'

const Form = ({ domain }) => {
  const { language } = useContext(StoreContext)
  return (
    <FeedbackForm
      currency={language}
      subdomain={domain}
      currentPageURL={typeof window !== `undefined` ? window.location : '/'}
    />
  )
}

export default Form
